import { types as api } from '@mesa-labs/mesa-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeaders, usePaymentApi } from './api';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.paymentUrl,
    prepareHeaders,
  }),
  tagTypes: ['Payments'],
  endpoints: (build) => ({
    getAllCustomerBatchTransfers: build.query<api.IPagedResults<api.RedactedCustomerBatchTransferResponse>, api.CustomerBatchTransferFilterParams>({
      async queryFn(params: api.IPageFilterParams, { dispatch }) {
        const externalPaymentApi = usePaymentApi(dispatch);
        const data = await externalPaymentApi.getAllCollatedTransfers<api.RedactedCustomerBatchTransferResponse>(params);
        return { data };
      },
    }),
    createDirectDebitAuthorization: build.mutation<api.DirectDebitAuthorizationResponse, void>({
      async queryFn(_, { dispatch }) {
        const externalPaymentApi = usePaymentApi(dispatch);
        const data = await externalPaymentApi.createDirectDebitAuthorization();
        return { data };
      },
    }),
  }),
});

export const {
  useGetAllCustomerBatchTransfersQuery,
  useCreateDirectDebitAuthorizationMutation,
} = paymentsApi;

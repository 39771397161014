import * as Sentry from '@sentry/react';
import { AnyAction } from 'redux';

export const filterActionForSentry = (action: AnyAction) => {
  try {
    return {
      // only capture the action type, but do not collect any
      // payload data to avoid inadvertent PII collection.
      type: action.type,
    };
  } catch (err) {
    // return an empty action to avoid inadvertent capturing of PII
    return null;
  }
};

export default Sentry.createReduxEnhancer({
  // remove sensitive information from captured actions
  actionTransformer: (action) => filterActionForSentry(action),
  // do not collect state information
  stateTransformer: () => null,
});

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import mixpanel from 'mixpanel-browser';

import packageJson from '../package.json';

import App from './components/App';
import { FeatureFlagsProvider } from './contexts/FeatureFlagProvider';
import { persistor, store } from './redux/store';

// force reload the page if vite encounters an error dynamically loading a
// module.
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

Sentry.init({
  dsn: CONFIG.sentry.dsn,
  environment: process.env.NODE_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  // add suffix to version if the NODE_ENV is not production
  // this allows for uploading of separate source maps for
  // overlapping version numbers in production and sandbox
  release: `${packageJson.version}${process.env.NODE_ENV === 'production' ? '' : `-${process.env.NODE_ENV}`}`,
  tracesSampleRate: CONFIG.sentry.tracesSampleRate,
  normalizeDepth: 5,
  ignoreErrors: [
    'CodeMismatchException',
    'UserNotFoundException',
    'Network Error',
    'Network error',
    'Forbidden',
    'Failed to fetch dynamically imported module',
    'Unable to preload CSS',
    '\'text/html\' is not a valid JavaScript MIME type.',
  ],
});

mixpanel.init(CONFIG.mixpanel.token, { debug: true });

const root = ReactDOM.createRoot(document.querySelector('#root')!);

root.render(
  <Provider store={store}>
    <FeatureFlagsProvider>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </FeatureFlagsProvider>
  </Provider>,
);

import {
  CsvFormat,
  ISODateString,
  SortDirection,
  UUID,
} from '@mesa-labs/mesa-api/dist/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const PaginationLimits = [
  { key: '10', value: 10 },
  { key: '25', value: 25 },
  { key: '50', value: 50 },
  { key: '100', value: 100 },
];

export type PaymentsState = {
  page: number;
  limit: { key: string; value: number };
  total?: number;
  searchTerm?: string;
  startDate?: ISODateString;
  endDate?: ISODateString;
  displayEndDate?: ISODateString;
  sortField?: string;
  sortDirection: SortDirection;
  visibleBatchTransferIds: Array<UUID>;
  exportFormat: CsvFormat;
};

const initialState: PaymentsState = {
  page: 1,
  limit: PaginationLimits[0],
  total: undefined,
  searchTerm: undefined,
  startDate: undefined,
  endDate: undefined,
  displayEndDate: undefined,
  sortField: 'transferInitiatedAt',
  sortDirection: SortDirection.DESCENDING,
  visibleBatchTransferIds: [],
  exportFormat: CsvFormat.MESA_CSV_FORMAT,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    updatePage(state: PaymentsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateLimit(state: PaymentsState, action: PayloadAction<{ key: string; value: number }>) {
      state.limit = action.payload;
    },
    updateTotal(state: PaymentsState, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    updateStartDate(state: PaymentsState, action: PayloadAction<ISODateString | undefined>) {
      state.startDate = action.payload;
    },
    updateEndDate(state: PaymentsState, action: PayloadAction<ISODateString | undefined>) {
      state.endDate = action.payload;
    },
    updateSearchTerm(state: PaymentsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateSortField(state: PaymentsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: PaymentsState, action: PayloadAction<SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateVisibleBatchTransferIds(state: PaymentsState, action: PayloadAction<Array<UUID>>) {
      state.visibleBatchTransferIds = action.payload;
    },
    updateExportFormat(state: PaymentsState, action: PayloadAction<CsvFormat>) {
      state.exportFormat = action.payload;
    },
  },
});

export const {
  updatePage,
  updateLimit,
  updateTotal,
  updateStartDate,
  updateEndDate,
  updateSearchTerm,
  updateSortField,
  updateSortDirection,
  updateVisibleBatchTransferIds,
  updateExportFormat,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { postSignIn } from './auth';

export type Vendor = {
  address: string;
  addressAdditional: string;
  city: string;
  email: string;
  firstName: string;
  hasAcceptedTos: boolean;
  isActive: boolean;
  lastName: string;
  name: string;
  dba: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  state: string;
  title: string;
  vendorId: api.UUID;
  zip: string;
  serviceAgreementSignedByName: string;
  serviceAgreementVersion: number;
  hasAcceptedServiceAgreement: boolean;
  partnerServiceAgreementVersion: number;
  partnerServiceAgreementSignedByName: string;
  hasAcceptedPartnerServiceAgreement: boolean;
  onboardingId?: api.UUID;
  referralCode?: api.ProgramCodes;
};

export type VendorState = {
  vendor: Vendor;
};

const initialState: VendorState = {
  vendor: {
    address: '',
    addressAdditional: '',
    city: '',
    email: '',
    firstName: '',
    hasAcceptedTos: false,
    isActive: false,
    lastName: '',
    name: '',
    dba: '',
    phoneNumber: '',
    phoneNumberExtension: '',
    state: '',
    title: '',
    vendorId: '',
    zip: '',
    serviceAgreementVersion: 0,
    serviceAgreementSignedByName: '',
    hasAcceptedServiceAgreement: false,
    partnerServiceAgreementVersion: 0,
    partnerServiceAgreementSignedByName: '',
    hasAcceptedPartnerServiceAgreement: false,
    onboardingId: '',
    referralCode: undefined,
  },
};

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    updateVendorField(state, action: PayloadAction<{ field: keyof Vendor, value: any }>) {
      const { payload } = action;

      state.vendor = {
        ...state.vendor,
        [payload.field]: payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSignIn.fulfilled, (state, action) => {
        state.vendor.referralCode = action.payload.referralCode;
      })
    },
});

export const {
  updateVendorField,
} = vendorSlice.actions;

export default vendorSlice.reducer;

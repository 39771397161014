import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISODateString } from '@mesa-labs/mesa-api/dist/types';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'cardinal' | 'unbranded';

interface UIState {
  demoMode: boolean;
  brand: Brand;
  excludeSupplementalFee: boolean;
  hasSeenGetPaidNow: boolean;
  inputMfaCodeComplete: boolean;
  showDashboardTour: boolean;
  showDashboardTourNavCTA: boolean;
  showFeedbackUI: boolean;
  showRequestInFlightsNotification: boolean;
  timesSeenFinishOnboardingCompletedState: number;
  singleToBulkInterstitialClickTimestamps: ISODateString[];
  showEarlyPaymentOpportunitiesGlobalNotification: boolean;
}

const initialState: UIState = {
  demoMode: false,
  brand: 'jll',
  excludeSupplementalFee: false,
  hasSeenGetPaidNow: false,
  inputMfaCodeComplete: false,
  showDashboardTour: false,
  showDashboardTourNavCTA: true,
  showFeedbackUI: true,
  showRequestInFlightsNotification: true,
  timesSeenFinishOnboardingCompletedState: 0,
  singleToBulkInterstitialClickTimestamps: [],
  showEarlyPaymentOpportunitiesGlobalNotification: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateBrand(state: UIState, action: PayloadAction<Brand>) {
      state.brand = action.payload;
    },
    updateShowDashboardTour(state: UIState, action: PayloadAction<boolean>) {
      state.showDashboardTour = action.payload;
    },
    updateInputMfaCodeComplete(state: UIState, action: PayloadAction<boolean>) {
      state.inputMfaCodeComplete = action.payload;
    },
    updateShowDashboardTourNavCTA(state: UIState, action: PayloadAction<boolean>) {
      state.showDashboardTourNavCTA = action.payload;
    },
    updateHasSeenGetPaidNow(state: UIState, action: PayloadAction<boolean>) {
      state.hasSeenGetPaidNow = action.payload;
    },
    updateDemoMode(state: UIState, action: PayloadAction<boolean>) {
      state.demoMode = action.payload;
    },
    updateExcludeSupplementalFee(state: UIState, action: PayloadAction<boolean>) {
      state.excludeSupplementalFee = action.payload;
    },
    updateTimesSeenFinishOnboardingCompletedState(state: UIState, action: PayloadAction<number>) {
      state.timesSeenFinishOnboardingCompletedState = action.payload;
    },
    updateShowRequestInFlightsNotification(state: UIState, action: PayloadAction<boolean>) {
      state.showRequestInFlightsNotification = action.payload;
    },
    updateShowFeedbackUI(state: UIState, action: PayloadAction<boolean>) {
      state.showFeedbackUI = action.payload;
    },
    updateSingleToBulkInterstitialClickTimestamps(state: UIState, action: PayloadAction<ISODateString>) { 
      // clear this out after 20 entries to avoid too large of a record:
      if (state.singleToBulkInterstitialClickTimestamps.length === 19) {
        state.singleToBulkInterstitialClickTimestamps = [];
      } else {
        state.singleToBulkInterstitialClickTimestamps.push(action.payload);
      }
    },
    updateShowEarlyPaymentOpportunitiesGlobalNotification(state: UIState, action: PayloadAction<boolean>) { 
      state.showEarlyPaymentOpportunitiesGlobalNotification = action.payload;
    }
  },
});

export const {
  updateBrand,
  updateShowDashboardTour,
  updateInputMfaCodeComplete,
  updateShowDashboardTourNavCTA,
  updateHasSeenGetPaidNow,
  updateDemoMode,
  updateExcludeSupplementalFee,
  updateTimesSeenFinishOnboardingCompletedState,
  updateShowRequestInFlightsNotification,
  updateShowFeedbackUI,
  updateSingleToBulkInterstitialClickTimestamps,
  updateShowEarlyPaymentOpportunitiesGlobalNotification,
} = uiSlice.actions;
export default uiSlice.reducer;

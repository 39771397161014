import CognitoBrowserService from '@mesa-labs/mesa-ui/dist/utils/cognito-browser';

export { default as CognitoBrowserService } from '@mesa-labs/mesa-ui/dist/utils/cognito-browser';

const DefaultCognitoProviderConfig = {
  UserPoolId: CONFIG.cognito.userPoolId,
  ClientId: CONFIG.cognito.userPoolWebClientId,
};

const cognitoProvider = new CognitoBrowserService(DefaultCognitoProviderConfig);

export default cognitoProvider;
